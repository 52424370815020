<template>
  <div class="lead-gencnt">
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>Lead Generate</h1>
      </div>
    </header>

    <section class="mid-cnt">
      <form class="fomr-box" @submit.prevent="generateLead">
        <div class="field-row">
          <label class="text-label">Name<span class="mark">*</span></label>
          <input v-model="name" class="input-style" placeholder="Name" @blur="$v.name.$touch()" />
          <p class="error" v-if="$v.name.$error">{{$t('_leadNameError')}}</p>
        </div>

        <div class="field-row">
          <label class="text-label"
            >Mobile Number<span class="mark">*</span></label
          >
          <input
            v-model="mobile"
            type="number"
            class="input-style"
            placeholder="Mobile Number"
            @blur="$v.mobile.$touch()"
          />
          <p class="error" v-if="$v.mobile.$error">{{$t('_leadMobileError')}}</p>
        </div>

        <div class="field-row">
          <label class="text-label">Address</label>

          <textarea
            v-model="address"
            class="textare-style"
            placeholder="Write Here..."
          >
          </textarea>
        </div>

        <div class="field-row">
          <label class="text-label"
            >Description<span class="mark">*</span></label
          >
          <textarea
            v-model="description"
            class="textare-style"
            placeholder="Write Here..."
            @blur="$v.description.$touch()"
          >
          </textarea>
          <p class="error" v-if="$v.description.$error">{{$t('_leadDescriptionError')}}</p>
        </div>
      </form>
    </section>

    <footer class="footer-bottom">
      <button class="button" @click="generateLead">Submit</button>
    </footer>
  </div>
</template>

<script>
import {required} from "@/utils/Validator"
import BackIcon from "@/components/svgIcons/BackIcon";
import ApiRequest from "@/services/ApiRequest";
import { BASE_API } from "@/utils/constants";
import { POST } from "@/utils/HttpStatusCodeConst";
import Swal from "sweetalert2";
export default {
  name: "LeadGenerate",
  components: {
    BackIcon,
  },
  data() {
    return {
      name: "",
      mobile: "",
      address: "",
      description: "",
      lead_id: this.$route.query.task_id,
    };
  },
  validations: {
    name: {
      required
    },
    mobile: {
      required
    },
    description: {
      required
    }
  },
  methods: {
    generateLead() {
      this.$v.$touch()
      if(!this.$v.$invalid){
        let form = {
          name: this.name,
          mobile: this.mobile,
          address: this.address,
          description: this.description
        }
        this.isLoading = true;
        ApiRequest(BASE_API, "/partner91/lead/create", POST, form)
          .then(({ result }) => {
            if (result.status){
              this.name = "";
              this.mobile = "";
              this.address = "";
              this.description = "";
              this.$v.$reset();
              Swal.fire({
                title: "Lead Generated Successfully",
                icon: "success",
              });
            }
            this.isLoading = false;
          })
          .catch((error) => {
            console.log("error:", error);
            Swal.fire({ text: "Something went wrong", icon: "error" });
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/lead-generate.scss";
</style>
